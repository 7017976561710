import { render, staticRenderFns } from "./MobileNavigation.vue?vue&type=template&id=12f3e3a6&scoped=true"
import script from "./MobileNavigation.vue?vue&type=script&lang=ts"
export * from "./MobileNavigation.vue?vue&type=script&lang=ts"
import style0 from "./MobileNavigation.vue?vue&type=style&index=0&id=12f3e3a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f3e3a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkCloseButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkCloseButton/RkCloseButton.vue').default,RkIcon: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkIcon/RkIcon.vue').default,RkLink: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkLink/RkLink.vue').default})
