






























































































































import {
  defineComponent,
  PropType,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch,
  nextTick,
} from '@nuxtjs/composition-api';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useUiState } from '~/composables';
import { useUiHelpers } from '~/composables';
import RkIcon from '~/rockitUI/components/components/atoms/RkIcon/RkIcon.vue';
import { isClient } from '~/rockitUI/components/utilities/helpers';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

export default defineComponent({
  components: { RkIcon },
  name: 'MobileNavigation',
  directives: { clickOutside },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { getCatLink } = useUiHelpers();

    const activeLvl1 = ref(null);
    const activeLvl2 = ref(null);

    const activeLvl1Menu = computed(() => {
      return props.categoryTree.find((cat) => cat.uid === activeLvl1.value);
    });

    const activeLvl2Menu = computed(() => {
      return activeLvl1Menu.value?.children.find((cat) => cat.uid === activeLvl2.value);
    });

    const selectLvl1 = (cat: string) => {
      activeLvl1.value = cat;
    };

    const selectLvl2 = (cat: string) => {
      activeLvl2.value = cat;
    };

    const menuClassLvl1 = (cat: string) => {
      return activeLvl1.value === cat ? 'translate-x-0' : 'translate-x-full';
    };

    const menuClassLvl2 = (cat: string) => {
      return activeLvl2.value === cat ? 'translate-x-0' : 'translate-x-full';
    };

    const debouncedWidth = ref(0);
    const widthTimeout = ref(null);

    const width = computed(() => {
      return debouncedWidth.value;
    });

    const setWidth = (val) => {
      widthTimeout.value = setTimeout(() => {
        debouncedWidth.value = val;
      }, 500);
    };

    const resizeHandler = (e) => {
      setWidth(window.innerWidth);
    };

    watch(
      width,
      (value) => {
        if (!isClient) return;
        if (value > 1028 && isMobileMenuOpen.value) {
          nextTick(() => {
            toggleMobileMenu();
          });
        }
      },
      { immediate: true }
    );

    watch(
      () => isMobileMenuOpen.value,
      (value) => {
        if (!isClient) return;
        if (value) {
          nextTick(() => {
            document.body.classList.add(
              'max-lg:fixed',
              'max-lg:overflow-hidden',
              'max-lg:w-screen'
            );
          });
        } else {
          document.body.classList.remove(
            'max-lg:fixed',
            'max-lg:overflow-hidden',
            'max-lg:w-screen'
          );
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setWidth(window.innerWidth);
      window.addEventListener('resize', resizeHandler);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler);
      document.body.classList.remove('max-lg:fixed', 'max-lg:overflow-hidden', 'max-lg:w-screen');
    });

    return {
      isMobileMenuOpen,
      toggleMobileMenu,
      getCatLink,
      activeLvl1,
      activeLvl2,
      selectLvl1,
      selectLvl2,
      menuClassLvl1,
      menuClassLvl2,
      activeLvl1Menu,
      activeLvl2Menu,
    };
  },
});
